import React from 'react';
import { Route } from 'react-router-dom';
import DocsCards from 'components/DocsCards/DocsCards';
import { appPaths } from 'consts/appPaths';
import LinuxNode from 'components/BlogPosts/Linux/LinuxNode';
import MonitorMultipleLinuxHosts from 'components/BlogPosts/Linux/MonitorMultipleLinuxHosts';
import KubernetesSelfHostedMetrics from 'components/BlogPosts/Kubernetes/KubernetesSelfHostedMetrics';
import { InstanceProvider } from 'api/InstanceProvider';
import JsonApi from 'components/BlogPosts/JsonApi/JsonApi';
import ServerlessPlatformMonitoring from 'components/BlogPosts/Serverless/ServerlessPlatformMonitoring';
import CSV from 'components/BlogPosts/CSV/CSV';

export const App: React.FC = () => {
  return (
    <InstanceProvider>
      <Route exact path="/a/grafana-selfservetutorials-app">
        <DocsCards />
      </Route>
      <Route exact path={appPaths.linuxNode}>
        <LinuxNode />
      </Route>
      <Route exact path={appPaths.selfHostedMetrics}>
        <KubernetesSelfHostedMetrics />
      </Route>
      <Route exact path={appPaths.multipleLinuxHosts}>
        <MonitorMultipleLinuxHosts />
      </Route>
      <Route exact path={appPaths.jsonApi}>
            <JsonApi />
      </Route>
       <Route exact path={appPaths.serverlessMonitoring}>
            <ServerlessPlatformMonitoring />
      </Route>
      <Route exact path={appPaths.csv}>
            <CSV />
      </Route>
    </InstanceProvider>
  );
};
export default App;

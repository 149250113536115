import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export const getStyles = (theme: GrafanaTheme2) => ({
  mainBackground: css`
    background: ${theme.colors.background.canvas};
    padding: 16px;
  `,

  globalContainer: css`
    background: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.medium};
  `,

  header: css`
    color: ${theme.colors.text.primary};
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 8px;

    p {
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }

    h1 {
      font-size: 26px;
      margin-bottom: 0;
    }
  `,

  searchBar: css`
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1024px) {
      width: 70%;
    }

    @media (max-width: 768px) {
      width: 85%;
    }

    @media (max-width: 489px) {
      width: 85%;
    }

    @media (min-width: 2001px) {
      width: 1200px;
    }
  `,

  cardContainer: css`
    max-width: 1440px;
    display: flex;
    width: 95%;
    justify-content: center;
    margin-top: 36px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 32px;
  `,

  grid: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 18px;
    width: 96%;

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  `,

  cards: css`
    padding: 2px 16px;
    margin-top: 16px;
    height: 210px;
    overflow: hidden;

    h3 {
      margin-top: 8px;
      margin-bottom: 16px;
      color: ${theme.colors.text.primary};
      @media (max-width: 1024px) {
        font-size: 18px;
      }
    }

    p {
      color: ${theme.colors.text.primary};
    }
    @media (max-width: 1024px) {
      height: 260px;
    }

    @media (max-width: 768px) {
      height: 225px;
    }

    @media (max-width: 600px) {
      height: 180px;
    }
  `,

  card: css`
    max-width: 500px;
    background: ${theme.colors.background.secondary};
    border-radius: 5px;
    border: 1px solid ${theme.colors.border.medium};

    img {
      width: 100%;
      height: auto;
      border-radius: 5px 5px 0 0;
    }

    a {
      margin: 0;
      padding: 0;
    }
  `,
});

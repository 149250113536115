import pluginJson from 'plugin.json';

export const basePath = `/a/${pluginJson.id}`;

export const appPaths = {
  basePath,
  linuxNode: `${basePath}/linux-node`,
  selfHostedMetrics: `${basePath}/self-hosted-metrics`,
  multipleLinuxHosts: `${basePath}/monitor-multiple-linux-hosts`,
  jsonApi: `${basePath}/docs-plugin/json-api`,
  serverlessMonitoring: `${basePath}/docs-plugin/serverless-monitoring`,
  csv: `${basePath}/docs-plugin/csv`,
};

import React, { useState } from 'react';
import { useStyles2, Input, Icon } from '@grafana/ui';
import { getStyles } from 'components/DocsCards/DocsCards.styles';
import { Card, cardData } from 'data/carddata';

const filterCards = (query: string) => {
  return cardData
    .map((post: any) => {
      if (query === '') {
        return post;
      } else if (post.title.toLowerCase().includes(query.toLowerCase())) {
        return post;
      } else {
        return null;
      }
    })
    .filter((post) => !!post);
};

function DocsCards() {
  const [query, setQuery] = useState('');
  const styles = useStyles2(getStyles);
  const cards: Card[] = filterCards(query);

  return (
    <div className={styles.mainBackground}>
      <div className={styles.globalContainer}>
        <div className={styles.header}>
          <h1 className={styles.header}>Grafana Cloud Tutorials</h1>
          <p>Explore more Grafana Cloud features to level up your Observability</p>
        </div>
        <div>
          <div className={styles.searchBar}>
            <Input
              prefix={<Icon name="search" />}
              placeholder="Search for tutorials"
              onChange={(event) => setQuery(event.currentTarget.value)}
            />
          </div>
          {cards?.length > 0 && (
            <div className={styles.cardContainer}>
              <div className={styles.grid}>
                {cards.map((cardItem: any) => {
                  return (
                    <div data-testid="card" className={styles.card} key={cardItem.id}>
                      <a href={`${cardItem.path}`}>
                        <img src={cardItem.heading} alt="Avatar" />
                        <div className={styles.cards}>
                          <h3>{cardItem.title}</h3>
                          <p>{cardItem.text}</p>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DocsCards;

import React, { useState } from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Card, ClipboardButton, Field, Icon, Input, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { TokenType } from 'components/AccessPolicyToken/token.enum';
import CreateAccessPolicyToken from './CreateAccessPolicyToken';
import { isAdmin } from 'utils/validation';

interface AccessPolicyTokenProps {
  token: string;
  setToken: (token: string) => void;
}

const AccessPolicyToken: React.FC<AccessPolicyTokenProps> = ({ setToken, token }) => {
  const getTokenStyles = (theme: GrafanaTheme2) => ({
    formGroup: css`
      margin-bottom: 20px;
    `,
    card: css`
      background-color: rgb(55,45,27);
    `,
    cardsHeader: css`
      margin-top: 0px !important;
    `
  });

  const tokenOptions = [
    { label: 'Create a new token', value: TokenType.NewKey },
    { label: 'Use an existing token', value: TokenType.ExistingKey },
  ];

  const styles = useStyles2(getTokenStyles);
  const [tokenType, setTokenType] = useState<TokenType>(TokenType.NewKey);

  if (!isAdmin) {
    return (

      <Card className={styles.card}>
        <Card.Heading className={styles.cardsHeader}>
          Permission denied
        </Card.Heading>
        <Card.Figure>
          <Icon name="exclamation-triangle" color="orange" />
        </Card.Figure>
        <Card.Description>
          Only admins have permissions to manage access policy tokens. 
          Please login to an organization where you are an admin, 
          or ask the admin to update your role.
        </Card.Description>
      </Card>
    )
  }
  return (
    <>
      <p>Use a Grafana.com access policy token</p>
      <div className={styles.formGroup}>
        <RadioButtonGroup value={tokenType} options={tokenOptions} onChange={(value) => setTokenType(value)} />
      </div>

      {tokenType === TokenType.ExistingKey ? (
        <Field label="Access Policy Token" required>
          <Input
            id="token-input"
            placeholder="Enter existing access policy token"
            addonAfter={<ClipboardButton icon="copy" variant="primary" getText={() => token}></ClipboardButton>}
            required
            onChange={(e) => {
              const token = e.currentTarget.value;
              setToken(token);
            }}
          />
        </Field>
      ) : (
        <CreateAccessPolicyToken setToken={setToken} />
      )}
    </>
  );
};

export default AccessPolicyToken;

import React, { useContext } from 'react';
import _ from 'lodash';

import { Alert } from '@grafana/ui';
import { GenerateToken, RealmTypes, AccessPoliciesProvider } from '@grafana-cloud/access-policies';
import { GrafanaComApiProvider } from '@grafana-cloud/grafana-com-api';
import { InstanceContext } from 'api/InstanceProvider';
import { QueryError } from 'types/QueryError';

interface CreateAccessPolicyTokenProps {
  setToken: (token: string) => void;
}

const CreateAccessPolicyToken: React.FC<CreateAccessPolicyTokenProps> = ({ setToken }) => {
  const { instanceData, error } = useContext(InstanceContext);

  const stackId = _.get(instanceData, 'id', 0);
  const region = _.get(instanceData, 'region', '');

  const scopes = {
    LOG_WRITE_SCOPE: 'logs:write',
    METRICS_WRITE_SCOPE: 'metrics:write',
    STACKS_READ_SCOPE: 'stacks:read',
  };
  if (error) {
    return (
      <div>
        <Alert title="Error" severity="error">
          <p>{(error as QueryError)?.data?.message}</p>
        </Alert>
      </div>
    );
  }

  return (
    <GrafanaComApiProvider
      config={{ baseApiUrl: 'api/plugin-proxy/grafana-selfservetutorials-app/grafanacom-api/auth' }}
    >
      <AccessPoliciesProvider
        config={{
          realm: { realmType: RealmTypes.STACK, realmIdentifier: String(stackId) },
          region,
        }}
      >
        <GenerateToken
          onTokenGenerated={(e) => setToken(e.token)}
          defaultTokenName="default-token-name-here"
          accessPolicyName="default-token-name"
          scopes={[scopes.LOG_WRITE_SCOPE, scopes.METRICS_WRITE_SCOPE, scopes.STACKS_READ_SCOPE]}
          realms={[
            {
              // see definition of Realms here:
              // https://grafana.com/docs/grafana-cloud/account-management/authentication-and-permissions/access-policies/#realms
              // In short, Realms define where access policy applies. It can be ORG (access to all stacks) or STACK level (single stack)
              // Provisioned plugins should always default to stack level unless absolutely necessary
              type: RealmTypes.STACK,
              identifier: String(stackId),
              labelPolicies: [],
            },
          ]}
        />
      </AccessPoliciesProvider>
    </GrafanaComApiProvider>
  );
};

export default CreateAccessPolicyToken;

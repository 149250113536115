import React, { useState } from 'react';
import { Icon, Select, useStyles2 } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { appPaths } from 'consts/appPaths';
import { getStyles } from 'components/TableOfContents/TableOfContentsDropdown.styles';

type Props = {
  headings: Array<{ value: string; label: string }>;
};

const TableOfContentsDropdown: React.FC<Props> = ({ headings }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleSelectChange = (e: SelectableValue<string>) => {
    const selectedId: string = e.value!;
    setSelectedOption(selectedId);
    const anchorElement = document.getElementById(selectedId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const styles = useStyles2(getStyles);

  return (
    <>
      <div className={styles.navigationContents}>
        <a href={appPaths.basePath}>
          <Icon name="arrow-left" /> Back to tutorials
        </a>
        <p>Contents:</p>
      </div>
      <div className={styles.container}>
        <Select
          className={styles.dropdown}
          value={selectedOption}
          options={headings}
          onChange={(v) => handleSelectChange(v)}
        />
      </div>
    </>
  );
};

export default TableOfContentsDropdown;

import { Alert, useStyles2 } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import step1a from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step1a.png';
import step1b from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step1b.png';
import step2a from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step2a.png';
import step3a from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step3a.png';
import step4a from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step4aa.png';
import step4b from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step4bb.png';
import step5a from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step5aa.png';
import step5b from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step5bb.png';
import step5c from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step5c.png';
import step5d from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step5d.png';
import step5e from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step5e.png';
import step6a from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step6a.png';
import step6b from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step6b.png';
import step6c from 'components/BlogPosts/Serverless/serverlessPlatformMonitoringImg/step6c.png';
import useDocsPluginVisited from 'hooks/useDocsPluginVisited';
import { ComponentKey } from 'types/ComponentKey';
import TableOfContents from 'components/TableOfContents/TableOfContents';
import TableOfContentsDropdown from 'components/TableOfContents/TableOfContentsDropdown';
import { InstanceContext } from 'api/InstanceProvider';
import _ from 'lodash';
import { QueryError } from '@grafana-cloud/grafana-com-api';
import { getStyles } from './ServerlessPlatformMonitoring.styles';

const ServerlessPlatformMonitoring = () => {
  const styles = useStyles2(getStyles);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
  const { instanceData, error } = useContext(InstanceContext);

  useDocsPluginVisited(ComponentKey.TutorialPluginAwsServerlessHasMadeItToBottom, _.get(instanceData, 'orgId', null));

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const headings = [
    { value: 'install', label: 'Install CloudWatch Metrics' },
    { value: 'configureJobs', label: 'Configure Scrape Jobs' },
    { value: 'autoConfigure', label: 'Auto Configure Scrape Jobs' },
    { value: 'useTerraform', label: 'Using Terraform' },
  ];

  if (error) {
    return (
      <div>
        <Alert title="Error" severity="error">
          <p>{(error as QueryError)?.data?.message}</p>
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.canvas}>
      <div className={styles.mainContent}>
        <TableOfContents headings={headings} />
        <div className={styles.container}>
          <>
            {isMobile && <TableOfContentsDropdown headings={headings} />}

            <div className={styles.headerSection}>
              <h1>Setting up AWS Lambda Monitoring</h1>
              <p>
                In this guide, you will learn how to set up serverless Monitoring with AWS lambda by configuring
                cloudwatch metrics and configuring scrape jobs using cloudformation
              </p>
            </div>

            <ol>
              <h2 id="install">Install the CloudWatch metrics integration for Grafana Cloud</h2>

              <li key="createADashboard">
                <p>If you have not created any dashboards in your new Grafana instance, select Create a dashboard.</p>
                <div className={styles.imageCard}>
                  <img src={step1a} alt="the first step" />

                  <hr />
                  <p>Grafana - Create a new dashboard (screenshot)</p>
                </div>

                <p>Otherwise, select +Connect data on the home screen</p>

                <div className={styles.imageCard}>
                  <img src={step1b} alt="the second step" />

                  <hr />
                  <p>Grafana - Connections page (screenshot)</p>
                </div>
              </li>
              <li key="searchCloudwatch">
                <p>Search for AWS and select the AWS tile.</p>

                <div className={styles.imageCard}>
                  <img src={step2a} alt="aws" />

                  <hr />
                  <p>Grafana - AWS Cloudwatch integration (screenshot)</p>
                </div>
              </li>
              <li key="clickCloudWatch">
                <p>Click the CloudWatch metrics tile.</p>

                <div className={styles.imageCard}>
                  <img src={step3a} alt="tile for cloudwatch" />

                  <hr />
                  <p>Cloudwatch Metrics tile (screenshot)</p>
                </div>
              </li>
              <h2 id="configureJobs">Configure scrape jobs</h2>
              <p id="autoConfigure">
                In order to get your data into Grafana Cloud, you need to configure a scrape job. A scrape job ingests
                data from a specified endpoint and stores it in your instance. You can create scrape job configurations
                automatically using CloudFormation or Terraform, or you can configure them manually.
                <a href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-cloudwatch/#install-cloudwatch-metrics-integration-for-grafana-cloud">
                  &nbsp;Manual configuration&nbsp;
                </a>
                requires many more steps, so we recommend automatic configuration.
              </p>
            </ol>
            <h3>CloudFormation automatically configure scrape jobs</h3>
            <p>If you only have cloud deployments on AWS, we recommend scraping with CloudFormation. </p>
            <ol>
              <li key="navigateConfigDetails">
                <p>If you are not already in the Configuration Details tab, navigate to that tab now.</p>
              </li>
              <li key="selectCloudform">
                <p>Select the Automatically and Use CloudFormation tiles.</p>
              </li>
              <div className={styles.imageCard}>
                <img src={step4a} alt="cloud formation" />

                <hr />
                <p>Grafana - Cloudformation tile (screenshot)</p>
              </div>
              <li key="launchStack">
                <p>Click Launch stack to set up a new IAM role.</p>

                <div className={styles.imageCard}>
                  <img src={step4b} alt="I AM" />

                  <hr />
                  <p>Grafana - Launch stack (screenshot)</p>
                </div>
              </li>
              <li key="openAWS">
                <p>
                  This opens AWS, which prompts you to log in to your AWS console. Leave the fields as they are and
                  create the stack.
                </p>
                <div className={styles.imageCard}>
                  <img src={step5a} alt="aws console" />
                  <hr />
                  <p>AWS - Console login (screenshot)</p>
                </div>
                <div className={styles.imageCard}>
                  <img src={step5b} alt="aws stack" />

                  <hr />
                  <p>AWS - Stack configuration page (screenshot)</p>
                </div>
              </li>
              <li key="outputs">
                <p>
                  After your stack is created, navigate to Outputs in your stack to find your ARN value and copy it to
                  Notepad or text editor.
                </p>
                <div className={styles.imageCard}>
                  <img src={step5c} alt="output navigation" />
                  <hr />
                  <p>AWS - ARN output values (screenshot)</p>
                </div>
              </li>
              <li key="copyRegion">
                <p>Copy your region as well in the top right navigation bar into Notepad or text editor.</p>
                <div className={styles.imageCard}>
                  <img src={step5d} alt="region screen" />

                  <hr />
                  <p>AWS - Select region (screenshot)</p>
                </div>
              </li>
              <li key="returnToInstance">
                <p>
                  Return to your Grafana instance, and complete the ARN (Amazon Resource Name) value from your
                  CloudWatch stack and the region your CloudWatch stack is in to test your connection.
                </p>
                <div className={styles.imageCard}>
                  <img src={step5e} alt="grafana instance" />
                  <hr />
                  <p>Grafana - Amazon resource name set up (screenshot)</p>
                </div>
              </li>

              <li key="servicesToScrape">
                <p>
                  Give your scrape job a name, add the services you want to scrape, and create your scrape job. After
                  it’s complete, you can install it and view your dashboard.
                </p>
                <div className={styles.imageCard}>
                  <img src={step6a} alt="job name" />
                  <hr />
                  <p>Grafana - Scrape job name (screenshot)</p>
                </div>
                <div className={styles.imageCard}>
                  <img src={step6b} alt="services" />

                  <hr />
                  <p>Grafana - Select services (screenshot)</p>
                </div>
                <div className={styles.imageCard}>
                  <img src={step6c} alt="scrape job" />

                  <hr />
                  <p>Grafana - View instance scrape job (screenshot)</p>
                </div>
              </li>

              <h3 key="useTerraform" id="useTerraform">
                Use Terraform to automatically configure scrape jobs
              </h3>
              <p>
                If you have multiple cloud deployments, we recommend using Terraform as it supports all cloud vendors
                such as AWS, GCP, Azure, and many others. To configure scrape jobs using Terraform,
                <a href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-cloudwatch/#automatically-configure-scrape-jobs-using-terraform">
                  visit Automatically configure scrape jobs using Terraform.
                </a>
              </p>
            </ol>
          </>
        </div>
      </div>
    </div>
  );
};

export default ServerlessPlatformMonitoring;

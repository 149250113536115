import React, { useEffect, useState, useContext } from 'react';
import { Alert, useStyles2 } from '@grafana/ui';
import _ from 'lodash';

import { InstanceContext } from 'api/InstanceProvider';
import TableOfContents from 'components/TableOfContents/TableOfContents';
import { ansibleConfig, deployAgentYML, inventoryFile } from 'components/BlogPosts/Linux/snippets';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import AccessPolicyToken from 'components/AccessPolicyToken/AccessPolicyToken';
import TableOfContentsDropdown from 'components/TableOfContents/TableOfContentsDropdown';
import { getStyles } from 'components/BlogPosts/Linux/MonitorMultipleLinuxHosts.styles';
import { ComponentKey } from 'types/ComponentKey';
import useDocsPluginVisited from 'hooks/useDocsPluginVisited';
import { QueryError } from 'types/QueryError';
import ViewDashboard from 'components/BlogPosts/Linux/LinuxImg/viewDashboards.png'
import CheckLogs from 'components/BlogPosts/Linux/LinuxImg/checkLogs.png'
import CheckMetrics from 'components/BlogPosts/Linux/LinuxImg/checkMetrics.png'


const MonitorMultipleLinuxHosts = () => {
  const styles = useStyles2(getStyles);
  const [token, setToken] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
  const { instanceData, error } = useContext(InstanceContext);

  useDocsPluginVisited(
    ComponentKey.TutorialPluginMonitorMultipleLinuxHostsHasMadeItToBottom,
    _.get(instanceData, 'orgId', null)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const headings = [
    { value: 'ansible', label: 'Install The Grafana Ansible Collection' },
    { value: 'inventoryFile', label: 'Create an Ansible inventory file' },
    { value: 'linuxNodeIntegration', label: 'Install the Linux Node integration for Grafana Cloud' },
    { value: 'checkMetricsGcloud', label: 'Check that logs and metrics are being ingested into Grafana Cloud' },
    { value: 'checkMetrics', label: 'Check metrics' },
    { value: 'viewDashboards', label: 'View dashboards' },
  ];

  const promId = _.get(instanceData, 'promId', 0);
  const promUrl = _.get(instanceData, 'promUrl', '');
  const lokiId = _.get(instanceData, 'lokiId', 0);
  const lokiUrl = _.get(instanceData, 'lokiUrl', '');

  if (error) {
    return (
      <div>
        <Alert title="Error" severity="error">
          <p>{(error as QueryError)?.data?.message}</p>
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.canvas}>
      <div className={styles.mainContent}>
        <TableOfContents headings={headings} />
        {instanceData === null && <>loading</>}
        <div className={styles.container}>
          <>
            {isMobile && <TableOfContentsDropdown headings={headings} />}
            <h1>Monitoring multiple Linux hosts with Grafana Agent Role</h1>
            <p>
              Monitoring with Grafana Agents across multiple Linux hosts can be difficult. To make it easier, you can
              use the Grafana Agent role with the{' '}
              <a href="https://github.com/grafana/grafana-ansible-collection" target="_blank" rel="noreferrer">
                Grafana Ansible collection
              </a>
              .
            </p>
            <h2>Before you begin</h2>
            <p>Before you begin, you should have:</p>
            <ul>
              <li key="availableLinuxHost">Linux hosts</li>
              <li key="sshAccess">SSH access to the Linux hosts</li>
              <li key="accountPermissions">
                Account permissions sufficient to install and use Grafana Agent on the Linux hosts
              </li>
              <li id="ansible" key="availableAccessPolicy">
                An Access Policy Token
              </li>
            </ul>
            <h4>Generate token</h4>
              <AccessPolicyToken token={token} setToken={setToken} />
            <div>
              <h2>Install the Grafana Ansible collection</h2>
            </div>
            <p>
              The{' '}
              <a
                href="https://github.com/grafana/grafana-ansible-collection/tree/main/roles/grafana_agent"
                className={styles.a}
                target="_blank"
                rel="noreferrer"
              >
                Grafana Agent role&#8599;
              </a>
              is available in the Grafana Ansible collection as of the 1.1.0 release.
            </p>
            <p id="inventoryFile">To install the Grafana Ansible collection, run this command:</p>
            <CopyToClipboard
              snippet={'ansible-galaxy collection install grafana.grafana:1.1.1'}
              height={'40px'}
              width={'100%'}
            />
            <div>
              <h2>Create an Ansible inventory file</h2>
            </div>
            <p>Next, you will set up your hosts and create an inventory file.</p>
            <ol>
              <li key="ssh">Create your hosts and add public SSH keys to them.</li>
              <p>
                This example uses eight Linux hosts: two Ubuntu hosts, two CentOS hosts, two Fedora hosts, and two
                Debian hosts.
              </p>
              <li key="inventoryFile">Create an Ansible inventory file.</li>
              <p>The Ansible inventory, which resides in a file named inventory, looks similar to this:</p>
              <CopyToClipboard snippet={inventoryFile} height={'200px'} width={'100%'} />
              <p>
                <i>
                  <b>Note: If you are copying the above file, remove the comments (#).</b>
                </i>
              </p>
              <li id="linuxNodeIntegration" key="linuxNodeIntegration">
                Create an ansible.cfg file within the same directory as inventory, with the following values:
              </li>
              <CopyToClipboard snippet={ansibleConfig} height={'100px'} width={'100%'} />
            </ol>
            <div>
              <h2>Install the Linux Node integration for Grafana Cloud</h2>
            </div>
            <p>
              Next you will create an Ansible playbook that calls the grafana_agent role from the grafana.grafana
              Ansible collection.
            </p>
            <p>To use the Grafana Agent Ansible role:</p>
            <ol>
              <li key="deployAgent">
                Create a file named deploy-agent.yml in the same directory as ansible.cfg and inventory and add the
                configuration below.
              </li>
              {promId && lokiId && promUrl && lokiUrl ? (
                <CopyToClipboard
                  snippet={deployAgentYML(promId, lokiId, promUrl, lokiUrl, token)}
                  height={'500px'}
                  width={'100%'}
                />
              ) : (
                <></>
              )}

              <CopyToClipboard
                snippet={deployAgentYML(promId, lokiId, promUrl, lokiUrl, token)}
                height={'500px'}
                width={'100%'}
              />

              <p>The playbook calls the grafana_agent role from the grafana.grafana Ansible collection.</p>
              <p>
                The Agent configuration in this playbook send metrics and logs from the linux hosts to Grafana Cloud
                along with the hostname of each instance
              </p>
              <p>
                Refer to the
                <a
                  href="https://github.com/grafana/grafana-ansible-collection/tree/main/roles/grafana_agent#role-variables"
                  className={styles.a}
                  target="_blank"
                  rel="noreferrer"
                >
                  Grafana Ansible documentation&#8599;
                </a>
                to understand the other variables you can pass to the grafana_agent role.
              </p>
              <p>
                When deploying the Agent across multiple instances for monitoring them, It is essential that the Agent
                is able to auto-detect the hostname for ease in monitoring. Notice that the label instance has been set
                to the value &#123;$HOSTNAME:-default&#125;, which is substituted by the value of the HOSTNAME
                environment variable in the Linux host.
              </p>
              <p>
                To read more about the variable substitution, refer to the Grafana Agent{' '}
                <a
                  href="https://grafana.com/docs/agent/latest/configuration/integrations/node-exporter-config/"
                  className={styles.a}
                  target="_blank"
                  rel="noreferrer"
                >
                  node_exporter_config&#8599;
                </a>
                documentation.
              </p>
              <li id="checkMetricsGcloud" key="checkMetricsGcloud">
                To run the playbook, run this command:
              </li>
              <CopyToClipboard snippet={'ansible-playbook deploy-agent.yml'} height={'40px'} width={'100%'} />
              <p>
                <i>
                  <b>
                    Note: You can place the deploy-agent.yml, ansible.cfg and inventory files in different directories
                    based on your needs.
                  </b>
                </i>
              </p>
            </ol>
            <div>
              <h2>Check that logs and metrics are being ingested into Grafana Cloud</h2>
            </div>
            <p>
              Logs and metrics will soon be available in Grafana Cloud. To test this, use the Explore feature. Click the
              Explore icon (compass icon) in the vertical navigation bar.
            </p>
            <h3>Check logs</h3>
            <p>To check logs:</p>
            <ol>
              <li key="lokiDataSource">
                Use the dropdown menu at the top of the page to select your Loki logs data source.
              </li>
              <li key="lokiHostName">
                In the log browser, run the query &#123;instance=&ldquo;centos-01&ldquo;&#125; where centos-01 is the
                hostname of one of the Linux hosts.
              </li>
              <p>If you see log lines (shown in the example below), logs are being received.</p>
              <div className={styles.imageCard}>
              <img
                id="checkMetrics"
                src={CheckLogs}
                alt="Logs"
              />
              <p>Received Logs</p>
              </div>
              <p>If no log lines appear, logs are not being collected.</p>
            </ol>
            <div>
              <h3>Check metrics</h3>
            </div>
            <p>To check metrics:</p>
            <ol>
              <li key="prometheusDataSource">
                Use the dropdown menu at the top of the page to select your Prometheus data source.
              </li>
              <li key="prometheusHostName">
                Run the query &#123;instance=&ldquo;centos-01&ldquo;&#125; where centos-01 is the hostname of one of the
                Linux hosts.
              </li>
              <p>If you see a metrics graph and table (shown in the example below), metrics are being received.</p>
              <div className={styles.imageCard}>
              <img
                src={CheckMetrics}
                alt="metrics view"
              />
              <p>Received Metrics</p>
              </div>
              <p>If no metrics appear, metrics are not being collected.</p>
            </ol>
            <div id="viewDashboards">
              <h3>View dashboards</h3>
            </div>
            <p>
              Now that you have logs and metrics in Grafana, you can use dashboards to view them. Here&lsquo;s an
              example of one of the prebuilt dashboards included with the Linux integration:
            </p>
            <div className={styles.imageCard}>
            <img
              src={ViewDashboard}
              alt="dashboard"
            />
            <p>Pre Built Dashboard</p>
            </div>
            <p>
              Using the Instance dropdown in the dashboard, you can select from the hostnames where you deployed Grafana
              Agent and start monitoring them.
            </p>
          </>
        </div>
      </div>
    </div>
  );
};

export default MonitorMultipleLinuxHosts;

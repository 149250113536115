export enum ComponentKey {
  TutorialPluginKubernetesSelfHostedMetricsHasMadeItToBottom = 'tutorial-plugin-kubernetes-self-hosted-metrics-has-made-it-to-bottom',
  TutorialPluginLinuxNodeHasMadeItToBottom = 'tutorial-plugin-linux-node-has-made-it-to-bottom',
  TutorialPluginMonitorMultipleLinuxHostsHasMadeItToBottom = 'tutorial-plugin-monitor-multiple-linux-hosts-has-made-it-to-bottom',
  TutorialPluginEnteredFromDemoDashboard = 'tutorial-plugin-entered-from-demo-dashboard',
  TutorialPluginEnteredFromOnboardingFlow = 'tutorial-plugin-entered-from-onboarding-flow',
  TutorialPluginJSONAPIHasMadeItToBottom = 'tutorial-plugin-json-api-has-made-it-to-bottom',
  TutorialPluginCSVHasMadeItToBottom = 'tutorial-plugin-csv-has-made-it-to-bottom',
  TutorialPluginAwsServerlessHasMadeItToBottom = 'tutorial-plugin-aws-serverless-has-made-it-to-bottom',
}
